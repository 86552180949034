.BuyMMYMLP-container {
  padding-top: 4.65rem;
  padding-bottom: 3.1rem;
  width: 100%;
}

.BuyMMYMLP-container .section-title-block {
  display: flex;
  margin-bottom: 2.325rem;
  max-width: 58.4rem;
  width: 100%;
}

.BuyMMYMLP-container .cards-row {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.BuyMMYMLP-container .card {
  border: 1px solid hsla(0,0%,100%,.06274509803921569);
  border-radius: 0.4rem;
  background: hsla(0,0%,100%,.06274509803921569);
}

.BuyMMYMLP-container .card-header {
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-weight: 400;
  letter-spacing: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem;
}

.BuyMMYMLP-container .card-divider {
  height: 1px;
  background: #23263b;
}

.BuyMMYMLP-container .card-body {
  padding: 1.5rem;
}

.BuyMMYMLP-container .App-card-content {
  display: grid;
  grid-row-gap: 0.8rem;
}

.BuyMMYMLP-container .exchange-info-group:not(:last-child) {
  margin-bottom: 1.5rem;
}

.BuyMMYMLP-container .BuyGMXGLP-description {
  letter-spacing: 0;
  color: #a9a9b0;
}

.BuyMMYMLP-container .col-1 {
  display: inline-flex;
}

.BuyMMYMLP-container .buttons-group {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(3,1fr);
  width: 100%;
  margin-top: 1rem;
}

.BuyMMYMLP-container .btn-label {
  margin-left: 1.1625rem;
  font-weight: 400;
  line-height: 2.325rem;
  letter-spacing: 0;
  font-size: 2rem !important;
}

@media (max-width: 370px) {
  .BuyMMYMLP-container .cards-row {
      grid-template-columns: auto !important;
  }
}

@media (max-width: 400px) {
  .BuyMMYMLP-container .BuyGMXGLP-description {
      font-size: var(--font-base);
      line-height: 2rem;
  }

  .BuyMMYMLP-container .buttons-group {
    grid-template-columns: auto !important;
  }
}

@media (max-width: 600px) {
  .BuyMMYMLP-container .default-container {
      padding-left: 2.2rem;
      padding-right: 2.2rem;
  }
}

@media (max-width: 800px) {
  .BuyMMYMLP-container .section-title-block {
      margin-bottom: 1.5rem!important;
  }

  .BuyMMYMLP-container .cards-row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1200px) {
  .BuyMMYMLP-container .buttons-group {
      grid-template-columns: repeat(2,1fr);
  }
}