.modal-mint {
    background: hsla(0,0%,100%,.05);
    border: 2px solid hsla(0,0%,100%,.1);
    backdrop-filter: blur(35px);
    opacity:1;
}

.modal-mint .Modal-content {
    border-radius: 24px;
    max-width: 872px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #17181e;
    border:1px solid #3e404c;
    padding:10px;
}

.modal-mint .Modal-backdrop {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.7);
    overflow: hidden; 
}

.modal-mint .Modal-content .Modal-title-bar {
    margin-bottom: 0;
}

.modal-mint .Modal-content .divider {
    display: none;
}

.modal-mint .Modal-content .Modal-body {
    margin-top: 0;
}

.Modal-body .panel .left .info-wrap .nft-mobile {
    display: none;
}

.Modal-body .panel .left .mobile {
    display: none;
}

.Modal-body .panel {
    display: grid;
    grid-template-columns: 300px auto;
    overflow: hidden;
    gap: 24px;
}

.Modal-body .panel .left .info-wrap {
    position: relative;
    top: -3px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 16px;
}

.Modal-body .panel .left .info-wrap .info-container > div:not(:last-child) {
    margin-bottom: 8px;
}

.Modal-body .panel .left .info-wrap .info-container > div {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.Modal-body .panel .left .info-wrap .info-container > div .amount img {
    margin-right: 4px;
    width:28px;
    height:28px;
}

.Modal-body .panel .left .info-wrap .info-container > div .amount {
    font-weight: 700;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.Modal-body .panel .mint-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 8px;
}

.Modal-body .panel .mint-desc {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.Modal-body .panel .mint-desc a {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 500;
    text-decoration: none;
}

.Modal-body .panel .mint-desc a img {
    margin-left: 4px;
}

.Modal-body .panel .right .normal {
    height: 300px;
}

.Modal-body .panel .right .balance-box {
    display: grid;
    grid-template-columns: 160px auto;
    gap: 12px;
}

.Modal-body .panel .right .your-balance.number-nft {
    text-align: left;
}

.Modal-body .panel .right .your-balance {
    margin-top: 14px;
    margin-bottom: 4px;
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
}

.Modal-body .panel .right .btn-dropdown {
    background: hsla(0,0%,100%,.1);
    border-radius: 8px;
    width: 160px;
    height: 48px;
    border: none;
    position: relative;
    display: flex;
    align-items: baseline;
    padding: 8px 12px;
}

.Modal-body .panel .right .btn-dropdown .content-dropdown span {
    font-weight: 400;
    font-size: 12px;
    color: hsla(0,0%,100%,.6);
}

.Modal-body .panel .right button {
    outline: none;
    cursor: pointer;
}

.Modal-body .panel .right .btn-dropdown .content-dropdown {
    color: #fff;
    display: flex;
    height: 100%;
    align-items: center;
    width: 128px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
}

.Modal-body .panel .right .btn-dropdown .icon-down {
    position: absolute;
    top: 12px;
    right: 8px;
}

.Modal-body .panel .right .PositionDropdown-extra-options {
    position: relative;
}

.PositionDropdown-extra-options .menu-items {
    top: 1rem;
    min-width: 15.5rem;
}

.PositionDropdown-extra-options .menu-item>p {
    margin: 0;
    padding-left: 1rem;
}

.Modal-body .panel .right .your-balance {
    margin-top: 14px;
    margin-bottom: 4px;
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
}

.Modal-body .panel .right .your-balance span {
    color: rgb(255, 255, 255);
}

.nmGuK .btn-primary {
    border: none;
    background: rgb(3, 245, 174);
    border-radius: 8px;
    color: rgb(8, 7, 21);
    font-weight: 500;
    font-size: 14px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.Modal-body .panel .right .btn-primary {
    width: 100%;
    height: 48px;
    margin-bottom: 12px;
}

.Modal-body .panel .right .mint-stats {
    margin-bottom: 36px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px 16px;
    font-weight: 400;
    line-height: 140%;
}

.Modal-body .panel .right .mint-stats .first-box {
    margin-bottom: 16px;
}

.Modal-body .panel .right .mint-stats .mint-stats-label {
    font-size: 12px;
    display: flex;
}

.Modal-body .panel .right .mint-stats .mint-stats-amount {
    font-size: 14px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.Modal-body .panel .right .mint-stats .mint-stats-label .Tooltip {
    margin-left: 4px;
    position: relative;
    top: 2px;
}

.Modal-body .panel .right .mint-stats .mint-stats-amount img {
    width: 16px;
    margin-right: 4px;
}


.mint-stats-label .Tooltip .Tooltip-handle {
    cursor: help;
    position: relative;
    display: inline-flex;
    text-decoration: underline;
    -webkit-text-decoration-style: dashed;
    text-decoration-style: dashed;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    -webkit-text-decoration-color: hsla(0,0%,100%,.6);
    text-decoration-color: hsla(0,0%,100%,.6);
}

.Modal-body .panel .right .last-mint {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    height: 112px;
    position: relative;
}

.Modal-body .panel .right .last-mint .last-mint-label {
    position: absolute;
    top: -14px;
    left: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    background: rgb(38, 35, 36);
    padding: 4px 8px;
    width: fit-content;
}

.Modal-body .panel .right .last-mint .last-mint-images1 {
    padding-top: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 484px;
    -webkit-mask-image: linear-gradient(270deg, rgba(217, 217, 217, 0.2) 0%, rgb(217, 217, 217) 49.48%, rgba(217, 217, 217, 0.2) 100%);
}

.Modal-body .panel .right .last-mint .last-mint-images1 img {
    border-radius: 12px;
    width: 85px; 
    height: 85px;
}

.nft-mint-item img{
    text-align: center;
    margin: 0 auto;
    padding: 5px;
    background-color: #fff;
}

@media screen and (max-width: 768px) {
    .modal-mint .Modal-content {
        max-width: calc(100vw - 32px);
    }

    .Modal-body {
        padding: 0 16px 16px;
        margin: 0;
    }

    .Modal-body .panel {
        grid-template-columns: 100%;
        gap: 2px;
    }

    .Modal-body .panel .left .mobile {
        display: block;
    }

    .Modal-body .panel .left .mum1 {
        display: none;
    }

    .Modal-body .panel .left .info-wrap {
        padding: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        margin-top: 16px;
    }

    .Modal-body .panel .left .info-wrap .nft-mobile {
        display: block;
        height: 80px;
    }

    .Modal-body .panel .left .info-wrap .info-container > div {
        font-weight: 400;
        font-size: 12px !important;
    }

    .Modal-body .panel .left .info-wrap .info-container {
        flex: 1 1 0%;
        padding: 6px 16px 6px 0px;
    }

    .Modal-body .panel .left .info-wrap .info-container .amount {
        min-width: 100px;
        -webkit-box-pack: end;
        justify-content: flex-end;
        font-weight: 400 !important;
    }

    .Modal-body .panel .left .info-wrap .info-container .amount img {
        width: 16px;
    }

    .Modal-body .panel .right .normal .mint-title, .Modal-body .panel .right .normal .mint-desc {
        display: none;
    }

    .Modal-body .panel .right .normal .mint-title, .Modal-body .panel .right .normal .mint-desc {
        display: none;
    }

    .Modal-body .panel .right .mint-stats {
        margin-bottom: 16px;
        gap: 8px;
        grid-template-columns: 100%;
    }

    .Modal-body .panel .right .mint-stats > div:not(:last-child) {
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .Modal-body .panel .right .mint-stats .first-box {
        margin-bottom: 8px;
    }

    .Modal-body .panel .right .mint-stats div > div {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}

@media screen and (max-width: 700px) {
    .Modal-body .panel .mint-title {
        font-size: 14px !important;
    }

    .Modal-body .panel .mint-desc {
        font-size: 12px;
    }
}

@media screen and (max-width: 991px) {
    .Modal-body .panel .mint-title {
        font-size: 16px;
    }
}