.DashboardV2-projects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

.link-underline {
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  color: hsla(0,0%,100%,.7);
}

.link-underline:hover {
  color: white;
}

@media (max-width: 900px) {
  .DashboardV2-projects {
    grid-template-columns: 1fr;
  }
}
