.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-sm);
  border-radius: 3px;
  overflow: hidden;
  color: hsla(0,0%,100%,.7);
  background: #101233;
  box-shadow: inset 0 0 30px 5px hsl(0deg 0% 100% / 1%);
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
}

.Tab.block .Tab-option:hover {
  color: #080715;
  background: #03f5ae;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  color: #080715;
  background: #03f5ae;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: 1.4rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}
